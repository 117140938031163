/*
* ===================================================
*    Echarts styles
* ===================================================
*/

.legend-item {
  .deselected {
    text-decoration: line-through;
  }

  &:hover {
    cursor: pointer;
  }
}

.legend-circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  opacity: 0.8;
  color: $white;
  aspect-ratio: 1;
}

.axis-label {
  background-color: #E5E5E5;
  font-weight: 500;
  font-size: 1rem;
  padding: 3px;
}
