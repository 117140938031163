// this is the place for your variable overrides, paste variables from custom/_variables.scss here and set your values

// primary colour is set to false by default to enable multiple colour variants stylesheet creation,
// change to your brand colour if needed
$primary: #0041ff;
$secondary: #bd00ff;

.btn-primary {
  background-color: #D7FF60 !important;
  border-color: #D7FF60 !important;
  color: #000000 !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background-color: darken(#D7FF60, 10%) !important;
  border-color: darken(#D7FF60, 10%) !important;
}

.btn-outline-primary {
  color: #000 !important;
  border-color: #D7FF60 !important;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active {
  background-color: #D7FF60 !important;
}


// This is here just as an example, uncomment the lines here and see it in action
// $primary:       #ef5285;
// $secondary:     #429244;
// $font-family-sans-serif:        -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
// $font-family-heading:           -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;